// config.ts
export interface Config {
  API_BASE_URL: string;
}

const config: Config = {
  API_BASE_URL:   import.meta.env.VITE_API_BASE_URL as string,
};

export default config;


export const index_cols: { [key: string]: string }  = {
  "intent": "up_id,sha256_lc_hem,rooturl,page_url,event_time_utc",
  "universal_person": "up_id,first_name,last_name,business_email,mobile_phone,direct_number,personal_phone,linkedin_url,personal_state,personal_zip,personal_zip4,personal_emails,gender,age_range,married,children,income_range,net_worth,homeowner,job_title,seniority_level,department,professional_address,professional_address_2,professional_city,professional_state,professional_zip,professional_zip4,primary_industry,cc_id,company_name,company_domain,company_phone,company_sic,company_address,company_city,company_state,company_zip,company_linkedin_url,company_revenue,company_employee_count"
};

export const USER_ROLES: { [key: string]: string } = {
  ADMIN: 'admin',
  USER: 'user',
  APIUSER:"apiuser"
};
