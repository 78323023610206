import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNotification } from '../contexts/NotificationContext';

const GoogleLogin = () => {
    const [redirectUrl] = useState("/");
    const [loginError, setLoginError] = useState("");
    const { loginUser, curUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { showNotification } = useNotification();

    useEffect(() => {
        // Dynamically load the Google API script
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = () => initGoogleSignIn(); // Initialize after script loads
        document.body.appendChild(script);

        return () => {
            // Clean up the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    const initGoogleSignIn = () => {
        window.google.accounts.id.initialize({
            client_id: "606952268806-9oefd9cd61k3r83e4pi2af9q00lfsguh.apps.googleusercontent.com",
            callback: handleCallback,
        });

        window.google.accounts.id.renderButton(
            document.getElementById("loginButtonDiv"),
            { size: 'large', type: "standard", text: "Sign in with Google", logo_alignment: "left", width: "300", theme: "filled_blue" }
        );
    };

    useEffect(() => {
        if (curUser && curUser.userid) {
            const from = location.state?.from?.pathname || redirectUrl;
            navigate(from, { replace: true });
        }
    }, [curUser, navigate, location.state, redirectUrl]);

    const handleCallback = async (token:any) => {
        const credential = token.credential as string;
        if (credential) {
            const json = JSON.stringify(token);
            try {
                await loginUser(json);
            } catch (err) {
                showNotification(`Failed to authenticate the user ${err}`, 'error', 'top');
                setLoginError(`Failed to authenticate the user ${err}`);
            }
        } else {
            setLoginError("Failed to authenticate the user");
            showNotification("Failed to authenticate the user", 'error', 'top');
        }
    };

    return (
        <div style={{ width: '100%', minHeight: '100px', background: '#fff', textAlign: 'center' }}>
            <div id="loginButtonDiv" style={{ padding: '20px', display: 'flex', justifyContent: 'center', fontSize: '25px' }}></div>
            {loginError && <div>{loginError}</div>}
        </div>
    );
};

export default GoogleLogin;
