// src/components/Layout.tsx
import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
 
import LeftSidebar from './LeftSidebar';


import { IndexInfoProvider } from '../contexts/IndexInfoContext';
 
import QueryBuilderComponent from './QueryBuilderComponent';
import TopBar from './TopBar';
import { UserQueryListProvider } from '../contexts/UserQueryListContext';

const Layout: React.FC = () => {

    const [isSidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  
  return (

    

    <Box display="flex" flexDirection="column" height="100vh" sx={{ display: 'flex' ,width: '100vw', maxWidth: '100%', m: 0, p: 0 }}>
        <IndexInfoProvider>
       <UserQueryListProvider>
        
      <CssBaseline />
            <TopBar handleDrawerToggle={toggleSidebar}></TopBar>
      <Box display="flex" flex="1">
      {isSidebarVisible && <LeftSidebar />}
        <Box component="main" flex="1" p={2}>
        
        
         
      <QueryBuilderComponent  />
           
         
    </Box>
         
      </Box>
      
       
      </UserQueryListProvider>
      </IndexInfoProvider>
    </Box>
  );
};

export default Layout;
