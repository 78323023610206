import React, { useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';
import GoogleLogin from './components/GoogleLogin';
import loginRightImage from './assets/login_rightjpg.jpg'; 

const Login: React.FC = () => {
  useEffect(() => {
    const fontLink = document.createElement('link');
    fontLink.href = 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';
    fontLink.rel = 'stylesheet';
    document.head.appendChild(fontLink);

    return () => {
      document.head.removeChild(fontLink);
    };
  }, []);

  return (
    <Box 
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        backgroundColor: '#f5f5f5', // Light background for better contrast
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          width: '80vw',
          maxWidth: '1200px',
          height: '80vh',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff', // White background for the container
        }}
      >
        <Box 
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <img src="/logo.png" alt="Company Logo" style={{ marginTop: '20px' }} />
            <Typography variant="h4" sx={{ marginBottom: '20px',  marginTop: '50px' }}>Sign up</Typography>
            <GoogleLogin />
          </Box>
          <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '20px'  }} >
            I agree to abide by templatana's <Link href="#">Terms of Service</Link> and its <Link href="#">Privacy Policy</Link>
          </Typography>
        </Box>
        <Box 
          sx={{
            flex: 1,
            backgroundImage: `url(${loginRightImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Add any content you need to center inside the right side here */}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
