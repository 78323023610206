import { saveAs } from 'file-saver';
   
 import config from '../config';

import axiosInstance from './axiosInstance';


export const downloadfilesvc = (filePath:string) => {

    if (filePath)
        {
  if (filePath)
    {
  const apiUrl = `${config.API_BASE_URL}/DownloadFile?filePath=${encodeURIComponent(filePath)}`;
  
  axiosInstance.get(apiUrl, {
    responseType: 'blob'  
  })
  .then(response => {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
     
    let filename = ((filePath+'').split('\\').pop() || 'queryresult.csv').split('/').pop();

    // Correct way to access headers in Axios
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (filenameMatch && filenameMatch[1]) {
        // Remove quotes around the filename if present
        filename = filenameMatch[1].replace(/['"]/g, '');
      }
    }
    saveAs(blob, filename);
  })
  .catch(error => {
    console.error('Error downloading the file: ', error);
  });}}
}
