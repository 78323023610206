export const formatRecordCount = (count: number): string => {
    if (count < 1000) {
      return count.toString(); // Less than 1000, show actual number
    } else if (count < 1000000) {
      return `${(count / 1000).toFixed(1)}K`; // Less than 1 million, show in thousands with "K"
    } else {
      return `${(count / 1000000).toFixed(1)}M`; // 1 million or more, show in millions with "M"
    }
  };

  export function formatFieldLabel(inputString:string) {
    // Step 1: Replace underscores and non-alphabetic characters with a space
    let formattedString = inputString.replace(/[^a-zA-Z0-9]+/g, ' ');
  
    // Step 2: Trim leading and trailing spaces and replace multiple spaces with a single space
    formattedString = formattedString.replace(/\s+/g, ' ').trim();
  
    // Step 3: Convert to proper case (capitalize the first letter of each word)
    formattedString = formattedString.replace(/\b(\w)/g, char => char.toUpperCase());
  
    return formattedString;
  } 
  