import React, { createContext, useContext, useState, ReactNode, useEffect, SyntheticEvent } from 'react';
import { Snackbar, Alert, AlertColor, SnackbarCloseReason } from '@mui/material';

type NotificationType = 'success' | 'error' | 'warning' | 'info';
type VerticalPosition = 'top' | 'bottom';

interface Notification {
  open: boolean;
  message: string;
  type: NotificationType;
  vertical: VerticalPosition;
}

interface NotificationContextType {
  showNotification: (message: string, type: NotificationType, vertical?: VerticalPosition) => void;
}

const NotificationContext = createContext<NotificationContextType | null>(null);

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (context === null) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notification, setNotification] = useState<Notification>({
    open: false,
    message: '',
    type: 'success',
    vertical: 'top',
  });

  const showNotification = (message: string, type: NotificationType, vertical: VerticalPosition = 'top') => {
    setNotification({ open: true, message, type, vertical });
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    console.log(event);
    if (reason === 'clickaway') {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    if (notification.open) {
      const timer = setTimeout(() => {
        setNotification((prev) => ({ ...prev, open: false }));
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: notification.vertical, horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={notification.type as AlertColor} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
