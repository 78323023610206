
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

import { fetchIndexDetails } from '../services/indexService'

import { InstantSearchIndexInfo ,InstantSearchColumn} from '../models';
import { index_cols }  from '../config';

interface IndexInfoContextType {
    indexDetails: InstantSearchIndexInfo[];
    fetchDetails: () => void;
}

const IndexInfoContext = createContext<IndexInfoContextType | undefined>(undefined);

 
interface IndexInfoProviderProps {
    children: ReactNode;  
}

export const IndexInfoProvider: React.FC<IndexInfoProviderProps> = ({ children }) => {
    const [indexDetails, setIndexDetails] = useState<InstantSearchIndexInfo[]>([]);

    const fetchDetails = async () => {
        let details = await fetchIndexDetails();
        details=reorderColumns(details);
        setIndexDetails(details);
    };

    useEffect(() => {
        fetchDetails();
    }, []);

    function reorderColumns( indexes: InstantSearchIndexInfo[]): InstantSearchIndexInfo[] {
     
     
        const reorderedIndexes = indexes.map(indexInfo => {
           let indexname= indexInfo.indexName;
           if (indexname.indexOf('intent')!=-1) indexname='intent';
           if (indexname.indexOf('universla_person')!=-1) indexname='universla_person';
           if (index_cols[indexname]){
            const allowedColumns = index_cols[indexname].split(',');
           
            let orderedColumns :InstantSearchColumn[]= [];
            allowedColumns.forEach(colName => {
                const column = indexInfo.columns.find(column => column.name === colName);
                if (column) {
                    orderedColumns.push(column);   
                }
            });
            return {...indexInfo, columns: orderedColumns}
            }else{
                return indexInfo;
            }
        });
    
        return reorderedIndexes;
    }

    
    return (
        <IndexInfoContext.Provider value={{ indexDetails, fetchDetails }}>
            {children}
        </IndexInfoContext.Provider>
    );
};

export const useIndexInfo = () => {
    const context = useContext(IndexInfoContext);
    if (context === undefined) {
        throw new Error('useIndexInfo must be used within a IndexInfoProvider');
    }
    return context;
};