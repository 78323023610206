import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import AsyncSelect from 'react-select/async';
import { CSSObjectWithLabel, OptionProps, StylesConfig, components,GroupBase } from 'react-select';
import { fetchDistinctValues } from '../services/dataService';
import {  MultiValue } from 'react-select';
import {  SelectOption } from '../models';
import debounce from 'lodash.debounce';
import { Checkbox,  MenuItem,Box, Typography,  IconButton, List, ListItem, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatFieldLabel, formatRecordCount } from '../contexts/utils';
 
 
interface CustomValueEditorProps {
  //field: string;
  table: string;
  sql:string;
 // currentOperator: string;
  onChangeDone: (value: string[]) => void;
  onFileImport: (rules: any) => void;
 // selectedOptions: SelectOption[];
  props: any
}

const CustomValueEditor: React.FC<CustomValueEditorProps> = ({
  table,
  //field,
  sql,
  //currentOperator,
  onChangeDone,
  onFileImport,
  //selectedOptions: initialSelectedOptions,
  props
  
}) => {
  const field:string=props.field;
  const currentOperator:string=props.operator;


  const initializedata  =():SelectOption[]=>
    {
      if (Array.isArray(props.value))
        {
          const filteredValues = props.value.filter((item:string) => item);
          return  filteredValues.map((m:string)=>({value:m}));
        }else{
          if(props.value)
          return [{value:props.value, label:props.value}];
          else 
          return [];
        }
       
    }

    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
      initializedata()  
    );
  const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);
  
  
  //const [ setInputText] = useState(''); // State to store the input text

  const handleInputChange= (newValue:string) => {
     
    //setInputText(newValue); 
    return newValue;  
  };

  useEffect(() => {
    
    const allSelected = allOptions.length > 0 && allOptions.every(option => 
        selectedOptions.some(selected => selected.value === option.value)
        
    );
    setSelectAllChecked(allSelected);
     
}, [allOptions, selectedOptions]);
 


const loadOptions = useCallback(debounce((inputValue: string,  callback: (options: SelectOption[]) => void) => {
   
    {
      
  fetchDistinctValues(table, field, inputValue, sql).then(data => {
    const options = data.map((item: any) => (
       {
      value: item.value,
      label: formatRecordCount(item.recordcount),
      isSelected: selectedOptions.some(option => option.value === item.value)
    }));
    setAllOptions(options);
    callback(options);
  });} 
}, 1000), [table, field, sql,selectedOptions]); 



      

  const handleChange = (newValue: MultiValue<SelectOption>) => {
    setSelectedOptions([...newValue]);
  };
 
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedOptions(allOptions); // Select all
    } else {
      setSelectedOptions([]); // Clear selection
    }
    setSelectAllChecked(event.target.checked);
  };
  


  const handleBlur = () => {
     
    onChangeDone(selectedOptions.map(option => option.value));
    
  };

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props} >
        <MenuItem style={{ background: '#f0f0f0',  }} >
          <Box display="flex" width="100%"     alignItems="flex-middle" paddingX={2}>
          <Checkbox  disableRipple
          checked={isSelectAllChecked}
          onChange={handleSelectAll}
          onClick={(event) => event.stopPropagation()} 
          ></Checkbox>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} style={{ flex: 1 }}>{formatFieldLabel(field)}</Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} flexShrink={0} style={{ marginLeft: 'auto' }}>Impressions</Typography>
          </Box>
        </MenuItem>
        {props.children}
      </components.MenuList>
    );
  };
  const customStyles: StylesConfig<SelectOption, true> = {
    option: (provided: CSSObjectWithLabel, state: OptionProps<SelectOption, true, GroupBase<SelectOption>>) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
      color: 'black',  
    })
     
     
  };
  
  const Option = (props: any) => (
    <components.Option {...props}>
      <MenuItem key={props.value} value={props.value} > 
      <Box display="flex" width="100%" alignItems="center" >
      <Checkbox
        
        checked={props.isSelected}
         
        disableRipple
         
      />
       <Typography   style={{ flex: 1 }}>{props.value}</Typography>
            <Typography  flexShrink={0} style={{ marginLeft: 'auto' }}>{props.label}</Typography>
         

      </Box>
      </MenuItem>
      
    </components.Option>
  ); 
  const CustomMultiValue = () => null;
  const [lastActionWasRemoval, setLastActionWasRemoval] = useState(false);

  const handleRemoveselected = (optionToRemove:string) => {
    const updatedOptions = selectedOptions.filter(option => option.value !== optionToRemove);
    setSelectedOptions(updatedOptions);
    setLastActionWasRemoval(true);
  }
  useEffect(() => {
    if (lastActionWasRemoval) {
      handleBlur();
      setLastActionWasRemoval(false);  
    }
  }, [selectedOptions]);  

 
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const lines = text.split('\n').filter(line => line.trim() !== '');

        let rules;
        if (currentOperator === "in" || currentOperator === "notIn") {
          rules = [{ field, operator: currentOperator, value: lines, type: 'string' }];
        } else {
          rules = lines.map(line => ({ field, operator: currentOperator, value: line, type: 'string' }));
          
        } 
        onFileImport({ combinator: 'or', rules });       
      };
      reader.readAsText(file);
    }
  };
  const fileInputRef = React.useRef<HTMLInputElement>(null);
   

  const handleBlurtext = (event:any) => {
    
    const c = event.target.value;
    setSelectedOptions([c]);
     onChangeDone(c);     
    
}
  const handleButtonClick = () => {
    fileInputRef.current?.click();   
  };
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box minWidth="500px">
       
      { (field.indexOf('url') !== -1||field.indexOf('email') !== -1) ? (
                <TextField style={{minWidth:"500px", backgroundColor: 'white'}} 
                size="small" variant="outlined"    onBlur={(event)=>handleBlurtext(event)}   />
            ) :(
         
     
    
      <AsyncSelect
    
        cacheOptions
    defaultOptions
    loadOptions={loadOptions}
    onInputChange={handleInputChange}
     onChange= {handleChange}
      isClearable={true}
    isSearchable={true}
    isMulti={true}
    value={selectedOptions}
    closeMenuOnSelect={false}
    blurInputOnSelect={false}
    hideSelectedOptions={false}
    styles={customStyles}
    components={ {
        Option: Option,
        MenuList:MenuList,
        MultiValue: CustomMultiValue,
        
        //MultiValueContainer: NoOptionsMessage 
      } }
    onBlur={handleBlur}/>
    )}</Box>
  <input
  type="file"
  ref={fileInputRef}
  onChange={handleFileUpload}
  style={{ display: 'none' }}   
/>
<IconButton
        color="primary"
        onClick={handleButtonClick}
        aria-label="upload file"
      >
        <CloudUploadIcon />
      </IconButton>
    
      <List style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)', 
       
      width: "100%",
      maxHeight: '122px',
      overflowY: 'auto', 
      padding: '0px', 
    }}>
      {
              
         
        selectedOptions.map(option => (
        <ListItem key={option.value} style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',  
           
        }}>
           
          <IconButton edge="end" aria-label="delete" title="Delete item?" onClick={() => handleRemoveselected(option.value)}>
            <DeleteIcon />  
          </IconButton><span style={{ 
             overflow: 'hidden', 
             textOverflow: 'ellipsis',  
             whiteSpace: 'nowrap',  
            
             marginLeft: '4px', }} title={option.value}>{option.value}</span>
        </ListItem>
      ))}
    </List> 
        

    
      
      
 </Box>


  );
};

export default CustomValueEditor;
