 
import { DataGrid, GridColDef } from '@mui/x-data-grid';



interface DataGridComponentProps {    
    data :Array<{ [key: string]: any }>
    columns:GridColDef[]
}

const InstantSearchDataGrid: React.FC<DataGridComponentProps> = ({ data , columns}) => {
    
     
    return (
        <div style={{ height: 400, width: '100%' }}>
           
            <DataGrid
                rows={data}
                columns={columns}

                checkboxSelection
                
            />
        </div>
    );
};

export default InstantSearchDataGrid;
