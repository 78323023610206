
import axios, { AxiosError, AxiosResponse } from 'axios';
import 'axios';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';
declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean; 
  }
}
interface AuthResponse {
  token: string;
  refreshToken: string;
}

const axiosInstance = axios.create({
  baseURL: config.API_BASE_URL
});
axiosInstance.interceptors.request.use(
  config => {
       
      const token = localStorage.getItem('token');
      if (token) {
          
          config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
  },
  error => {
       
      return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response, // on fulfilled
  async (error: AxiosError) => {
    const originalRequest = error.config;
    // Check if originalRequest exists and the response is a 401 and has not already been retried
    if (originalRequest && error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  

      try {
       
        const oldtoken = localStorage.getItem('token');
        if (oldtoken) {
          const tokenResponse = await axios.post<AuthResponse>('/Account/RefreshToken', { Token: oldtoken });
          const  token  = tokenResponse.data.token;
          localStorage.setItem('token', token);  
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axios(originalRequest);  
        }
      } catch (refreshError) {
        const auth = useAuth();
        auth.logout();  
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
