

import config  from '../config';
import axiosInstance from './axiosInstance';
import { UserQuery } from '../models';


export const fetchIndexDetails = async () => {
    try {
         
        const response = await axiosInstance.get(`${config.API_BASE_URL}/GetIndexDetails`);
        return response.data;
         
    } catch (error) {
        console.error('Failed to fetch index details:', error);
        throw error;
    }
}


export const fetchUserQueryList = async () => {
    try {         
        const response = await axiosInstance.get<UserQuery[]>(`${config.API_BASE_URL}/ListUserQuery`);
        return response.data ;         
    } catch (error) {
        console.error('Failed to fetch index details:', error);
        return [];
    }
}

