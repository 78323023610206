import axios from 'axios';
import config   from '../config';
import axiosInstance from './axiosInstance';
 
 
export const  googleLoginAsync= async (token:string) => {
   
    try {
         
         const response = await axios.post( `${config.API_BASE_URL}/Account/GoogleAuth`,{ Token: token });
        return (response.data);           
     
    }catch (error) {
        console.error('Googel login error:', error);
        throw error;
    }
}
export const  validateTokenAsync= async (token:string) => {
   
    try {
         const response = await axiosInstance.post(`${config.API_BASE_URL}/Account/validateToken`,{ Token: token });
        return (response.data);           
     
    }catch (error) {
        console.error('Googel login error:', error);
        throw error;
    }
}
