
import React, { useState , useEffect} from 'react';
import { QueryBuilderMaterial } from '@react-querybuilder/material';

import {QueryBuilder, RuleGroupType , formatQuery, RuleType} from 'react-querybuilder';
import { useIndexInfo } from '../contexts/IndexInfoContext';
import InstantSearchDataGrid from './DataGridComponent';
import CustomValueEditor from './CustomValueEditor';

import {  InstantSearchsearchResponse,   WebApiDownloadQueryResult, WebApiResult } from '../models';
import { executeInstantSearchsearchQuery, fetchDataAndCreateCsvAsync, saveUserQuery } from '../services/dataService';
import DownloadComponent from './downloadFileComponent';
  
import { Button, ThemeProvider, Typography, Box,createTheme, LinearProgress, TextField } from '@mui/material';
import { index_cols } from '../config';
import { GridColDef } from '@mui/x-data-grid';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { useAuth } from '../contexts/AuthContext';
import axios, { CancelTokenSource } from 'axios';
import { useQueryList } from '../contexts/UserQueryListContext';
import { useNotification } from '../contexts/NotificationContext';
 

const QueryBuilderComponent: React.FC = () => {
    const { indexDetails } = useIndexInfo();
    
    
    const [ griddata, setgridData] = useState<Array<{ [key: string]: any }>>([]);
    const { curUser } = useAuth();
    const [gridcolumns, setgridColumns] = useState<GridColDef[]>([{ field: '', headerName: '', width: 90 }]);
    const {currUserQuery, setCurrentQuery}= useQueryList();
    
    const [queryName, setQueryName] = useState(currUserQuery.queryname);
    const [queryDesc, setQueryDesc] = useState(currUserQuery.querydesc);
     
      
    const muiTheme = createTheme();

    const [fields, setFields] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [SaveResulting, setSaveResult] = useState<boolean>(false);

    const [queyresp, setQueyresp] = useState<WebApiDownloadQueryResult>({ 
        files: [],
        success: false,
        timespan: 0,
        message:'',
        recordcount:0

    });
     
    useEffect(() => {
        setQueryName(currUserQuery.queryname);
        setQueryDesc(currUserQuery.querydesc);
        let cq= { combinator: 'and', rules: [] };
        if(currUserQuery.queryjson)
            {
                cq= JSON.parse(currUserQuery.queryjson) ;
                
                 

            }
        
        if (currUserQuery.indexname) {
            const selectedDetails = indexDetails.find(info => info.indexName === currUserQuery.indexname);
            const newFields = selectedDetails ? selectedDetails.columns.map(col => ({
                name: col.name,
                label: col.name,
                type: col.type 
            })) : [];
            setFields(newFields);
            setQuery(cq);
            setgridData([]);
            setgridColumns([]);
        } else {
            setFields([]);  
            setgridData([]);
            setgridColumns([]);
        }
    }, [indexDetails,currUserQuery ]); 
/*
    const getInitialValuesForField = (field: string, query: RuleGroupType): SelectOption[] => {
        let initialValues: SelectOption[] = [];
    
        const findValues = (rules: Array<RuleType | RuleGroupType>) => {
          rules.forEach(rule => {
            if(rule)
                {
            if ('rules' in rule) {
              findValues(rule.rules);
            } else if (rule.field === field && rule.value !== undefined) {

                if (rule.value.length>0)
                    {
                        if(Array.isArray(rule.value))   
                        {                       
                            rule.value.forEach((v:string) =>{initialValues.push({
                                value: v, 
                                label: v
                            });});
                        }else{
                           
                            initialValues.push({value:rule.value,label:rule.value});
                        }
                    }
                
            }
          }});
        };
    
        if (query.rules) {
          findValues(query.rules);
        }
        return initialValues;
      };
    
       */

    const handleFileImport = (importedRules: RuleGroupType) => {
         
        setQuery(importedRules);   
    };
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

    const handleCancelDownload = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Download cancelled by the user.');
            setCancelTokenSource(null); 
            setLoading(false);
        }
    };
    const handleSaveQueryResult= async ()=>{

        setLoading(true);  
        setSaveResult(true);
        if (cancelTokenSource) {
            cancelTokenSource.cancel("SaveQueryResult process is canceled by the user.");
        }

        const newCancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);
        
        let response :WebApiDownloadQueryResult= { 
            files: [],
            success: false,
            timespan: 0,
            message:'',
            recordcount:0

        };
        setQueyresp(response);  
        const sql= getfullsqls(false);
        try {
            response = await fetchDataAndCreateCsvAsync(sql,newCancelTokenSource.token ) as WebApiDownloadQueryResult;
            
             if (!response.success)
                {
                    showNotification(response.message, "error", "top");
                     
                }
        } catch (error) {
            if (!axios.isCancel(error)) {
                 
                console.log(error);
            }
        }  
        setQueyresp(response);
        setLoading(false);  
        setSaveResult(false);
    }

    const haswherecondition = (rules: Array<RuleType | RuleGroupType>):boolean => {
        let rtn= false;
        rules.forEach(rule => {
          if ('rules' in rule) {
            rtn= haswherecondition(rule.rules);
          } else {

              if (rule.value.length>0)
                  {
                    rtn= true;
                    
                  }
              
          }
           
        });
        return rtn;
      };
  

    const getfullsqls =(isshortform:boolean)=>{

        const hasqueryfilter=haswherecondition(query.rules);
        if (!hasqueryfilter)
            return `select * from ${currUserQuery.indexname}`;

        if (isshortform)
            return `select * from ${currUserQuery.indexname} where ${formatQuery(query, 'sql')}`;
        else{

        var where= formatQuery(query,  {format:'sql',quoteValuesWith: '^^^'});
        where = where.replaceAll("'","''");
        where= where.replaceAll("^^^","'");
        let indexname=currUserQuery.indexname;
        if (indexname.indexOf('intent')!=-1) indexname='intent';
         
        const selectcolumns=(!index_cols[indexname])?"*":index_cols[indexname];
         

        const formattedQuery =  `select ${selectcolumns} from ${currUserQuery.indexname} where ${where}`; 
        return formattedQuery;
        }
    }
 
    const [query, setQuery] = useState<RuleGroupType>({ combinator: 'and', rules: [] });
     
    const handleQueryExecution = async() => {
         
        const formattedQuery =  getfullsqls(false);
        
        
        setQueyresp({ 
            files: [],
            success: false,
            timespan: 0,
            message:'',
            recordcount:0

        });  
        await fetchData(formattedQuery);

    };



    const fetchData = async (sql:string) => {
        setLoading(true);
        try {
            
            setQueyresp({ 
            files: [],
            success: false,
            timespan: 0,
            message:'',
            recordcount:0

        });  
            const response = await executeInstantSearchsearchQuery(sql) as WebApiResult;
             
            if (response.success)
                {
            const obj : InstantSearchsearchResponse= JSON.parse(response.instantjsonsresult);
            
            const dataRows = obj.rows.map((row, rowindex) => {
                let rowData: { [key: string]: any } = {};
                row.forEach((cell, index) => {
                    rowData[obj.columns[index].name] = cell;
                });
                return    { id: rowindex, ...rowData };
            });
            setgridData(dataRows);
            const dataColumns: GridColDef[] = obj.columns.map(col => ({
                field: col.name,
                headerName: col.name.charAt(0).toUpperCase() + col.name.slice(1),
                 
                type: col.type === 'date' ? 'date' : 'string'  // Adjust types as needed
            }));
            
           setgridColumns(dataColumns);
            
            setQueyresp({ 
            files: [],
            success: true,
            timespan: response.timespan,
            message:'',
            recordcount:response.recordcount

        });  
            }else{
                setQueyresp({ 
                    files: [],
                    success: true,
                    timespan: response.timespan,
                    message: response.message,
                    recordcount:response.recordcount });  

                console.error('Failed to fetch data:', response.message);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
        setLoading(false);
    };
 
/*
    const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    getQuery();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  const getQuery = () => {
    const updatedUserQuery = {
        ...currUserQuery,
        queryjson: JSON.stringify(query),
        querysql: getfullsqls(false)
    };
    setCurrentQuery(updatedUserQuery);
}*/
const { showNotification } = useNotification();
const [queryNameError, setQueryNameError] = useState(false);
const userqueryhandler=async()=>
{
    if (!queryName) {
        setQueryNameError(true);
        return;
      }
      const uq= {...currUserQuery, 
        queryjson: JSON.stringify(query),
        querysql: getfullsqls(false),
        queryname: queryName,
        querydesc : queryDesc,
        indexname: currUserQuery.indexname,
        userid :curUser.userid,
        email:curUser.username
    };
    
      const s=await saveUserQuery(currUserQuery);
      
            uq.id=s;
          
       
      showNotification("query is saved", "success","bottom");
    
      
    setCurrentQuery(uq);
    //handleClose();
}

    return (
        <Box display="flex" flexDirection="column" minHeight="85vh">
        <Box flex="1">
         
            
    <Box  display="flex" alignItems="center" justifyContent="space-between"  sx={{ mb:1.5, width: '100%', maxWidth: '80vw' }}>
           
            
       <Typography variant="h5">Table {currUserQuery.indexname} </Typography>
       
        
        
      <Box  alignItems="end" >
        <Button   variant="outlined"    onClick={handleQueryExecution}>Execute Query</Button>
        <Button  variant="outlined" 
          onClick={handleSaveQueryResult} 
          sx={{ ml: 2 }}  
        >
          Download Query Result
        </Button>
        <Button   sx={{ ml: 2 }}   variant="outlined" onClick={userqueryhandler}>
        Save Query
      </Button>
        </Box>
        
      

        <Box display="flex" flexDirection="row" >
        {/* Record count */}
      <Box display="flex" alignItems="center" sx={{ whiteSpace: 'nowrap' }}>
        <FormatListNumberedIcon sx={{ mr: 0.5 }} />

        <Typography  title="Record count"  variant="subtitle1" style={{minWidth:50}}>{queyresp.recordcount===0?"":queyresp.recordcount.toLocaleString()}</Typography>
      </Box>
       
      {/* Execution time */}
      <Box display="flex" alignItems="center" sx={{ whiteSpace: 'nowrap' }}>
        <AccessTimeIcon sx={{ ml: 2, mr: 0.5 }} />
        <Typography title="Execution time" variant="subtitle1" style={{minWidth:50}}>{queyresp.timespan===0?"":`${queyresp.timespan.toLocaleString()} ms`}</Typography>
      </Box>
      </Box>
    </Box>      
    <TextField
          autoFocus
          margin="dense"
          id="query-name"
          label="Query Name"
          type="text"
          fullWidth
          variant="standard"
          value={queryName}
          onChange={e => {setQueryName(e.target.value); 
            if (e.target.value !== '') {
            setQueryNameError(false);
          }}}
          required
          error={queryNameError}
          helperText={queryNameError ? '*required' : ''}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />
        <TextField
          margin="dense"
          id="query-desc"
          label="Query Description"
          type="text"
          fullWidth
          variant="standard"
          multiline
          rows={4}
          value={queryDesc}
          onChange={e => setQueryDesc(e.target.value)}
       
      />
          
            {currUserQuery.indexname && fields.length > 0 && (
                 <ThemeProvider theme={muiTheme} >
                 <QueryBuilderMaterial>
                   <QueryBuilder                
                 
                    controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                    fields={fields}
                    query={query}
                    onQueryChange={setQuery}
                    
                    controlElements={{
                        valueEditor: (props) =><CustomValueEditor
                        table={currUserQuery.indexname}  
                         sql= {getfullsqls(true)}                       
                         
                         onFileImport={handleFileImport}
                         onChangeDone ={props.handleOnChange}
                         props= { props}
                         />
                      }}

                /></QueryBuilderMaterial>
                </ThemeProvider>
            
                    )}
         
       
        {loading &&   <Box sx={{ width: '100%' }}> <LinearProgress /> </Box>}
        {SaveResulting && <Button variant='text' onClick={handleCancelDownload}>   Cancel Download </Button>}
        {queyresp?.files.length > 0 && (
               <div>   <ol>

                    {queyresp.files.map((file, index) => (

                        <li key={index}>

                            <DownloadComponent fileUrl={file}></DownloadComponent>
                        </li>
                    ))}
                   

                </ol></div>
        )}
             {queyresp?.recordcount > 0 && (
                <InstantSearchDataGrid data={griddata} columns={gridcolumns} />
             )}</Box>
              <Box component="span" sx={{ p: 2, bgcolor: 'background.paper'}}>
              {`select * from ${currUserQuery.indexname} where ${formatQuery(query, 'sql')}`} 
      </Box>
       
        </Box>
    );
};

export default QueryBuilderComponent;
