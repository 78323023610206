import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute: React.FC = () => {
    const { curUser, isloading } = useAuth();

    if (isloading) {
        return <div>Loading...</div>;  
    }

    if (!curUser.userid) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};

export default PrivateRoute;
