import React from 'react';
import { downloadfilesvc } from '../services/downloadfileservice';

interface DownloadComponentProps {
    fileUrl: string;  // Preserved for use within downloadfilesvc
}

const DownloadComponent: React.FC<DownloadComponentProps> = ({ fileUrl }) => {
     
    const handleDownloadClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();  

        try {
             
            await downloadfilesvc(fileUrl);
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };

    return (
        // Use href="#" to indicate that the link is meant to be handled by JavaScript
        <a href="#" onClick={handleDownloadClick}>
            Download File
        </a>
    );
};

export default DownloadComponent;
