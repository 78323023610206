import {  createContext, useContext, useEffect, useState } from "react";
import { ContextProps, UserQuery } from "../models";
import React from "react";
import { fetchUserQueryList } from "../services/indexService";
 
interface UserQueryListContextProps {
    userqueries: UserQuery[];
    addUserQuery: (newquery:UserQuery) => void;
    currUserQuery: UserQuery; 
    setCurrentQuery: (query: UserQuery ) => void
  }
  const defaultUserQuery = {
    id: "",
    userid: "",
    email: "",
    queryname: "",
    indexname: "",
    querysql: "",
    queryjson: "",
    querydesc: ""
  };
  
  export default defaultUserQuery;
  const defaultContext: UserQueryListContextProps = {
    userqueries: [],
    addUserQuery: () => {}  ,
    currUserQuery: defaultUserQuery,
    setCurrentQuery:()=>{}
};

const UserQueryListContext =  createContext<UserQueryListContextProps >(defaultContext);

export const useQueryList = () => useContext(UserQueryListContext);
 

export const UserQueryListProvider : React.FC<ContextProps> = ({ children }) => {
    const [userqueries, setUserQueries] =useState<UserQuery[]>([]);
     
    const [currUserQuery, setCurrentQuery] = useState<UserQuery >( defaultUserQuery);
     

    useEffect(() => {
        const fetchAndInitializeQueries = async () => {
            
            
            const details = await fetchUserQueryList();
             
            var qs= details.filter((query) => query.id !== currUserQuery.id);
            if(currUserQuery.id){
            qs.push(currUserQuery);
            
            }
            setUserQueries(qs);
          };
          
          fetchAndInitializeQueries();

        }

    , [currUserQuery]);

    
    
    const addUserQuery = (newUserQuery: UserQuery) => {
        setUserQueries(prevQueries => {
             
            const existingQueryIndex = prevQueries.findIndex(q => q.id === newUserQuery.id);
            if (existingQueryIndex !== -1) {
                 
                const updatedQueries = [...prevQueries];
                updatedQueries[existingQueryIndex] = newUserQuery;
                return updatedQueries;
            } else {
                
                return [...prevQueries, newUserQuery];
            }
        });
    };


    return (
        <UserQueryListContext.Provider value={{ userqueries, addUserQuery,currUserQuery, setCurrentQuery }}>
            {children}
        </UserQueryListContext.Provider>
    );
};