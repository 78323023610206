import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useIndexInfo } from '../contexts/IndexInfoContext';
import defaultUserQuery, { useQueryList } from '../contexts/UserQueryListContext';
import { UserQuery } from '../models';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteUserQuery } from '../services/dataService';
import { useNotification } from '../contexts/NotificationContext';
import { useAuth } from '../contexts/AuthContext';
 

const LeftSidebar: React.FC = () => {
  const { indexDetails } = useIndexInfo();
  const { userqueries, currUserQuery, setCurrentQuery } = useQueryList();
  const { showNotification } = useNotification();
  const { curUser } = useAuth();

  const handleIndexClick = (indexName: string) => {
    if (currUserQuery.indexname !== indexName) {
      const q: UserQuery = {
        id: "",
        userid: curUser.userid,
        email: curUser.email,
        queryname: "",
        indexname: indexName,
        querysql: "",
        queryjson: "",
        querydesc: ""
      };
      setCurrentQuery(q);
    }
  };

  const formatRecordCount = (count: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(count);
  };

  const handlerqueryClick = (query: UserQuery) => {
    setCurrentQuery(query);
  };

  // delete userquery handler
  const [openDialog, setOpenDialog] = useState(false);

  const handleDeleteClick = (query: UserQuery) => {
    setCurrentQuery(query);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (currUserQuery.id) {
      try {
        const id = await deleteUserQuery(currUserQuery.id);
        if (id !== "") {
          setCurrentQuery(defaultUserQuery);
          showNotification("the query has been deleted.", "success", "bottom");
        }
      } catch (error: any) {
        showNotification(`failed to delete the query. ${error.message}`, "error", "top");
      }
      setOpenDialog(false);
    }
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  return (
    
    <Box
      component="aside"
      width="250px"
      p={2}
      bgcolor="grey.100"
      borderRight="1px solid grey.300"
      height="100vh"
    >
      <Box mb={2}>
        <Typography variant="h6">List of Tables</Typography>
        <List>
          {indexDetails.map(info => (
            <ListItem 
              button
              key={info.indexName}
              onClick={() => handleIndexClick(info.indexName)}
              style={{ 
                backgroundColor: currUserQuery.indexname === info.indexName ? 'rgba(0, 75, 183, 0.2)' : 'transparent', // Conditional background color
                borderRadius: '5px'  
              }}
            >
              <ListItemText 
                primary={
                  <span style={{ width: '100%' }}>
                    <b>{info.indexName}</b> ({formatRecordCount(info.recordCount)})
                  </span>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6">List of Saved Queries</Typography>
        <List>
          {userqueries.map((query) => (
            <ListItemButton key={query.id} selected={currUserQuery.id === query.id} onClick={() => handlerqueryClick(query)}>
              <ListItemText primary={query.queryname + "-" + query.id} />
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(query)}>
                <DeleteIcon />
              </IconButton>
            </ListItemButton>
          ))}
        </List>

        <Dialog
          open={openDialog}
          onClose={handleCancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the query "{currUserQuery.queryname + "-" + currUserQuery.id}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default LeftSidebar;
