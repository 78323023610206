 
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Button } from '@mui/material';
const LogoutButton = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login'); // Redirect to login page after logout
    };

    return (
         
        <Button variant="contained" onClick={handleLogout} endIcon={<ExitToAppIcon />}/>
         

    );
};

export default LogoutButton;
