
 
 import { FieldLookup,  UserQuery, WebApiDownloadQueryResult, WebApiResult} from'../models'
 import config from '../config';
import axiosInstance from './axiosInstance';
 
import   { CancelToken } from 'axios';

 export async function fetchDataAndCreateCsvAsync(  query:string,  cancelToken: CancelToken ): Promise<WebApiDownloadQueryResult> {
    
    if(!query)
         {
            var rtn: WebApiDownloadQueryResult = {
                files: [],
                message: "no sql statement"  ,
                 success:false,
                 timespan:-1,
                 recordcount:0
               };
            return rtn;
        } 
    try {
        var response = await axiosInstance.post<WebApiDownloadQueryResult>(`${config.API_BASE_URL}/FetchDataAndCreateCsvAsync`, 
        {
             Query: query,
             BatchSize:10000
        },  
        {
            cancelToken: cancelToken
        });
         
        return response.data;
    } catch (error) {
        console.error('Error downloading InstantSearchsearch query:', error);
        var rtn: WebApiDownloadQueryResult = {
            files: [],
            message: error instanceof Error ? error.message : "An unknown error occurred"  ,
             success:false,
             timespan:-1,
             recordcount:0
           };
        return rtn;
    }

}
export async function executeInstantSearchsearchQuery(query: string): Promise<WebApiResult> {
    
    if(!query)
         {
            var rtn: WebApiResult = {
                instantjsonsresult: "",
                message: "no sql statement"  ,
                 success:false,
                 timespan:-1,
                 recordcount:0
               };
            return rtn;
        } 
    try {
        var response = await axiosInstance.post<WebApiResult>(`${config.API_BASE_URL}/FetchIndexData`, {
            query: query
        });
         
        return response.data;
    } catch (error) {
        console.error('Error executing InstantSearchsearch query:', error);
        var rtn: WebApiResult = {
            instantjsonsresult: "",
            message: error instanceof Error ? error.message : "An unknown error occurred"  ,
             success:false,
             timespan:-1,
             recordcount:0
           };
        return rtn;
    }

}



export const fetchDistinctValues = async (tableName:string ,fieldName: string, inputValue: string, sql: string): Promise<FieldLookup[]> => {
 
    try {
        
        inputValue+="";
        sql='';
        const encodedInputValue = encodeURIComponent(inputValue);

        const url = `${config.API_BASE_URL}/GetDistinctValues?indexName=${tableName}&fieldName=${fieldName}&leadingValue=${encodedInputValue}&subquery=${sql}`;


        const response = await axiosInstance.get<FieldLookup[]>(url);
            
         
        return response.data;
         
       
    } catch (error) {
      console.error('Failed to fetch data', error);
      return [];
    }
  };

export const saveUserQuery = async (q:UserQuery)=>{
    try {
        

        const url = `${config.API_BASE_URL}/SaveUserQuery`;


        const response = await axiosInstance.post<string>(url,q );
            
         
            return response.data as string;
         
         
       
    } catch (error) {
      console.error('Failed to fetch data', error);
      return "";
    }
}

export const deleteUserQuery = async (qid:string)=>{
    try {
        

        const url = `${config.API_BASE_URL}/DeleteUserQuery?queryid=${qid}`;


        const response = await axiosInstance.delete<string>(url);
            
         
        return response.data;
         
       
    } catch (error) {
      console.error('Failed to fetch data', error);
      return [];
    }
}