import React from 'react';
import { AppBar, Toolbar, Typography, Avatar, Box, IconButton } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import LogoutButton from './LogoutButton';
import MenuIcon from '@mui/icons-material/Menu'; 
 

interface TopBarProps {
    handleDrawerToggle: () => void;
     
  }
const TopBar: React.FC<TopBarProps> = ({ handleDrawerToggle}) => {
  const { curUser } = useAuth();


  return (

     
     <>
     
    <AppBar position="static">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography display="flex"  variant="h4">
      
      <IconButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
      </Typography>     
        {/* Left side - Title */}
        <Box display="flex" alignItems="flex-start">
          <Typography variant="h5" style={{ marginLeft: 8 }}>
            IMS Instant Search
          </Typography>
        </Box>

         
        <Box display="flex" alignItems="center">
          
          <Avatar>{curUser.username ? curUser.username[0] : 'U'}</Avatar>
          <Typography variant="h6" style={{ marginLeft: 8 }}>
            Welcome, {curUser.username}
          </Typography>
           {curUser.roles?.indexOf("admin")!=-1 && <a>Manage Users</a>
            
            }
          <LogoutButton />
        </Box>
      </Toolbar>
    </AppBar>
    </>
  );
};

export default TopBar;
