// App.tsx
 
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'; // This is the home page
import Login from './Login';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute'; // Adjust the import path as necessary

import 'react-querybuilder/dist/query-builder.css';
import { NotificationProvider } from './contexts/NotificationContext';
  
const App = () => {
  return (
    <NotificationProvider>
    <Routes>
       <Route element={<PrivateRoute />}>
                <Route path="/" element={<Layout />} />
            </Route>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </NotificationProvider>
  );
};

export default App;
