import React, { createContext, useContext, useState, useEffect,ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { googleLoginAsync, validateTokenAsync } from '../services/authService';
import { AppUser } from '../models';
 
const defaultContext: AuthProviderPropsType = {
    curUser: {} as AppUser,  
    validateToken: () => {},  
    loginUser:()=>{},
    logout: () => {}  ,
    isloading:true
  };
const AuthContext = createContext<AuthProviderPropsType >(defaultContext);


export const useAuth = () => useContext(AuthContext);
interface AuthProviderPropsType {
    curUser:AppUser,
    validateToken: () => void;
    isloading :boolean;
    loginUser:(token:any)=>void;
    logout:()=>void;
}

interface AuthProviderProps {
    children: ReactNode;  
     
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children}) => {

    const [isloading, setLoading] = useState(true);
    const [curUser, setUser] = useState<AppUser>({});
    const navigate = useNavigate();
    // check if the cookie present, if there user 
    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        
        if (!token) {
            setUser({});
            setLoading(false);
            return;
        }

        try {
            const userres = await validateTokenAsync(token);
            setUser(userres);
            setLoading(false);
            if (userres && userres.userid) {
                navigate('/');
            }
        } catch (error) {
             
            setUser({});
            setLoading(false);
            localStorage.removeItem('token');
            navigate('/login');
        }
    };
    const loginUser = async (token: any) => {
        try {
            const userres = await googleLoginAsync(token);

            if (userres.userid) {
                
                localStorage.setItem('token', userres.token);
                setUser(userres);
            }
        } catch (error) {
            console.error('Login error:', error);
            localStorage.removeItem('token');
            setUser({});
            throw(error);
        }
    };
    const logout = () => {
        localStorage.removeItem('token');
        setUser({});
         
    };
    return (
        <AuthContext.Provider value={{ curUser, validateToken ,loginUser,logout, isloading}}>
            {children}
        </AuthContext.Provider>
    );
};
